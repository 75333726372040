import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const vacacionesLeyApi = createApi({
    reducerPath: 'vacacionesLeyApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "vacaciones/ley",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerVacacionLey: build.query({
            query: (idVacacionLey) => `/${idVacacionLey}`,
        }),
        obtenerVacacionesLey: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarVacacionLey: build.mutation({
            query: (vacacionLey) => ({
                url: '',
                method: vacacionLey.idVacacionLey ? 'PUT' : 'POST',
                body: vacacionLey,
            }),
        }),
        eliminarVacacionLey: build.mutation({
            query: (vacacionLey) => ({
                url: '',
                method: 'DELETE',
                body: vacacionLey,
            }),
        })
    })
})

export const {
    useObtenerVacacionLeyQuery,
    useObtenerVacacionesLeyQuery,
    useLazyObtenerVacacionesLeyQuery,
    useGuardarVacacionLeyMutation,
    useEliminarVacacionLeyMutation
} = vacacionesLeyApi