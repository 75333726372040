import {
    useObtenerVacacionesLeyQuery,
    useEliminarVacacionLeyMutation,
    useLazyObtenerVacacionesLeyQuery
} from '../../../services/vacacionLey.service'
import React, { useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import VacacionesLeyForm from './VacacionesLeyForm'
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import { ExportToExcel } from "../../../components/base/ExportToExcel";

const VacacionesLey = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formVacacionesLey, setFormVacacionesLey] = useState(null)
    const [confirmDialog, setConfirmDialog] = useState({});

    const {
        data: vacacionesLey, refetch
    } = useObtenerVacacionesLeyQuery([pageNumber, elementsPerPage], { refetchOnMountOrArgChange: true })

    const [trigger] = useLazyObtenerVacacionesLeyQuery()
    const [eliminarVacacionLey] = useEliminarVacacionLeyMutation()

    return (
        <div id="main" className="main">
            <section className="section">
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <div className="btn-toolbar justify-content-between" role="toolbar">
                                    <div className="pagetitle">
                                        <h1>Vacaciones de ley</h1>
                                    </div>
                                </div>
                                {vacacionesLey &&
                                    <BootstrapTable striped
                                        toolbar={({ columns }) => (
                                            <div>
                                                <Button href="#" className="me-2" variant="primary" onClick={(e) => {
                                                    setFormVacacionesLey({})
                                                }}>
                                                    <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                                </Button>
                                                <ExportToExcel columns={columns} onClick={trigger} fileName={'vacacionesLey'} />
                                            </div>
                                        )}
                                        data={vacacionesLey.data}
                                        columns={[{
                                            header: 'Inicio vigencia',
                                            accessorKey: 'anio'
                                        }, {
                                            header: 'Año inicial',
                                            accessorKey: "anioInicial"
                                        },
                                        {
                                            header: 'Año final',
                                            accessorKey: "anioFinal"
                                        }, {
                                            header: 'Dias de vacaciones',
                                            accessorKey: "diasVacaciones"
                                        }, {
                                            header: 'Estatus',
                                            accessorKey: 'estatus.estatus',
                                            enableGlobalFilter: false
                                        }, {
                                            header: 'Acciones',
                                            enableGlobalFilter: false,
                                            cell: ({ row: { original } }) => (
                                                <div className="w-100 d-grid d-sm-block gap-1" >
                                                    <Button variant="warning" className="me-2" size="sm"
                                                        onClick={e => setFormVacacionesLey(original)}>
                                                        <i className="bi bi-pencil-square"></i>
                                                        Editar
                                                    </Button>

                                                    <Button variant="danger" className="me-2" size="sm"
                                                        onClick={e => setConfirmDialog({
                                                            message: '¿Desea eliminar este registro?', onConfirm: async () => {
                                                                await eliminarVacacionLey({ idVacacionLey: original.idVacacionLey });
                                                                refetch();
                                                                setConfirmDialog({});
                                                            }
                                                        })}>
                                                        <i className="bi bi-trash"></i>
                                                        Eliminar
                                                    </Button>
                                                </div>)
                                        }]}
                                        onGlobalFilterChange={(e => {
                                            setPageNumber(1);
                                            setElementsPerPage(e ? -1 : 10);
                                        })}
                                    />
                                }
                                {vacacionesLey && <SeverPagination
                                    data={vacacionesLey.data}
                                    currentPage={pageNumber}
                                    totalElements={vacacionesLey.totalElements}
                                    elementsPerPage={elementsPerPage}
                                    onPageChange={setPageNumber}
                                    onElementsPerPageChange={setElementsPerPage}
                                />}
                            </Card.Body>
                            <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})} />
                        </Card>
                    </Col>
                </Row>
            </section>
            {formVacacionesLey && <VacacionesLeyForm show={formVacacionesLey} onHide={(e) => {
                setFormVacacionesLey(false);
            }} onSave={refetch} />}
        </div>
    )

}

export default VacacionesLey