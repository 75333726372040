import { createSlice } from '@reduxjs/toolkit';
import { archivosApi } from "../services/archivo.service";
import { toast } from "react-toastify";
import { solicitudesPagosApi } from "../services/solicitudPago.service";
import { reportesApi } from "../services/reporte.service";
import { observacionesApi } from "../services/observacion.service";
import { documentosApi } from "../services/documento.service";
import { procesoEtapaDocumentoApi } from "../services/procesoEtapaDocumento.service";
import { rolesApi } from "../services/rol.service";
import { rolesModulosApi } from "../services/rolModulo.service";
import { usuariosApi } from "../services/usuario.service";
import { bancosApi } from "../services/banco.service";
import { consultasApi } from "../services/consulta.service";
import { empresasApi } from "../services/empresa.service";
import { estatusApi } from "../services/estatus.service";
import { proyectosApi } from "../services/proyecto.service";
import { tiposMonedasApi } from "../services/tipoMoneda.service";
import { tiposPagosApi } from "../services/tipoPago.service";
import { usuariosModulosApi } from "../services/usuarioModulo.service";
import { procesoEtapaCampoApi } from "../services/procesoEtapaCampo.service";
import { turnosApi } from '../services/turno.service';
import { marcasApi } from '../services/marca.service';
import { prioridadesApi } from '../services/prioridades.service';
import { unidadesMedidasApi } from '../services/unidadesMedidas.service';
import { departamentosApi } from "../services/departamento.service"
import { categoriasApi } from "../services/categoria.service"
import { configuracionesCorreosApi } from "../services/configuracionCorreo.service"
import { regimenesFiscalesApi } from '../services/regimenFiscal.service';
import { protocolosCorreosApi } from '../services/protocoloCorreo.service';
import { tiposPersonasApi } from '../services/tipoPersona.service';
import { categoriasProductosApi } from "../services/categoriaProducto.service";
import { tiposProductosApi } from "../services/tipoProducto.service";
import { productosApi } from "../services/producto.service";
import { municipiosApi } from '../services/municipio.service';
import { almacenesApi } from "../services/almacen.service";
import { entidadesFederativasApi } from "../services/entidadFederativa.service";
import { paisesApi } from "../services/pais.service";
import { seccionesAlmacenesApi } from "../services/seccionAlmacen.service";
import { tiposSeccionesAlmacenesApi } from "../services/tipoSeccionAlmacen.service";
import { metodosSalidasAlmacenesApi } from "../services/metodoSalidaAlmacen.service";
import { pasillosAlmacenesApi } from "../services/pasilloAlmacen.service";
import { estanteriasAlmacenesApi } from "../services/estanteriaAlmacen.service";
import { metodosTrasladosAlmacenesApi } from "../services/metodoTrasladoAlmacen.service";
import { empleadosApi } from "../services/empleado.service";
import { solicitudesReembolsosApi } from '../services/solicitudReembolso.service';
import { impuestosApi } from "../services/impuesto.service";
import { origenesEntradasApi } from "../services/origenEntrada.service";
import { origenesSalidasApi } from "../services/origenSalida.service";
import { entradasApi } from "../services/entrada.service";
import { entradasProductosApi } from "../services/entradaProducto.service";
import { salidasApi } from "../services/salida.service";
import { salidasProductosApi } from "../services/salidaProducto.service";
import { coloresApi } from "../services/color.service";
import { inventariosApi } from "../services/inventario.service";
import { configuracionesAlmacenesApi } from "../services/configuracionAlmacen.service";
import { tiposTransportesApi } from "../services/tipoTransporte.service";
import { tiposCombustiblesApi } from "../services/tipoCombustible.service";
import { clasesTransportesApi } from "../services/claseTransporte.service";
import { marcasTransportesApi } from "../services/marcaTransporte.service";
import { fabricantesTransportesApi } from "../services/fabricanteTransporte.service";
import { transportesApi } from "../services/transporte.service";
import { mantenimientosApi } from "../services/mantenimiento.service";
import { tiposMantenimientosApi } from "../services/tipoMantenimiento.service";
import { mantenimientosPiezasApi } from "../services/mantenimientoPieza.service";
import { localidadesApi } from '../services/localidad.service';
import { tiposInstalacionesApi } from "../services/tipoInstalacion.service";
import { puestosApi } from '../services/puesto.service';
import { tiposDocumentosApi } from "../services/tipoDocumento.service";
import { configuracionesDocumentosApi } from '../services/configuracionDocumento.service';
import { diasFestivosApi } from '../services/diasFestivos.service';
import { vacacionesApi } from '../services/vacacion.service';
import { vacacionesLeyApi } from '../services/vacacionLey.service'


const defaultPendingMatcher = (state) => {
    state.loading++;
};

const defaultRejectedMatcher = (state, arg) => {
    state.loading--;
    if (arg.payload) {
        //error del servidor
        if (arg.payload.data?.advertencia) {
            toast.warn(<><strong>Advertencia.</strong> <br /> {arg.payload.data.advertencia} </>);
        } else if (arg.payload.data?.error) {
            toast.error(<><strong>Error.</strong> <br /> {arg.payload.data.error}</>);
        } else {
            toast.error(<><strong>Error.</strong> <br /> Ha ocurrido un error de aplicación. Contacte al administrador.</>);
        }
    } else {
        //error de frontend
        toast.error(<><strong>Error.</strong> <br /> Ha ocurrido un error de aplicación. Contacte al administrador.</>);
        console.error(arg)
    }

};

const defaultFulfilledMatcher = (state, { meta: { baseQueryMeta: { request } } }) => {
    state.loading--;
    if (['POST', 'PATCH', 'PUT'].includes(request.method)) {
        toast.success(<><strong>Éxito.</strong> <br /> Los datos han sido guardados.</>);
    } else if (['DELETE'].includes(request.method)) {
        toast.success(<><strong>Éxito.</strong> <br /> El registro ha sido eliminado.</>);
    }
};

const registerApiEndpoints = (builder, apiSlice, customMatchers = {}) => {
    for (const endpointName in apiSlice.endpoints) {
        const endpoint = apiSlice.endpoints[endpointName];
        builder.addMatcher(endpoint.matchPending, customMatchers[endpointName]?.matchPending ?? defaultPendingMatcher);
        builder.addMatcher(endpoint.matchRejected, customMatchers[endpointName]?.matchRejected ?? defaultRejectedMatcher);
        builder.addMatcher(endpoint.matchFulfilled, customMatchers[endpointName]?.matchFulfilled ?? defaultFulfilledMatcher);
    }
};

const globalSlice = createSlice({
    name: 'global', initialState: { loading: 0 }, reducers: {}, extraReducers: (builder) => {
        registerApiEndpoints(builder, archivosApi, {
            guardarArchivo: {
                matchFulfilled: (state) => {
                    state.loading--;
                    toast.success('El archivo ha sido cargado con éxito.');
                }
            }
        });
        registerApiEndpoints(builder, reportesApi);
        registerApiEndpoints(builder, consultasApi);
        registerApiEndpoints(builder, proyectosApi);
        registerApiEndpoints(builder, documentosApi);
        registerApiEndpoints(builder, tiposPagosApi);
        registerApiEndpoints(builder, tiposMonedasApi);
        registerApiEndpoints(builder, observacionesApi);
        registerApiEndpoints(builder, solicitudesPagosApi, {
            guardarSolicitudPago: {
                matchFulfilled: (state) => {
                    state.loading--;
                }
            }
        });
        registerApiEndpoints(builder, procesoEtapaCampoApi);
        registerApiEndpoints(builder, procesoEtapaDocumentoApi);

        //catalogos
        registerApiEndpoints(builder, bancosApi);
        registerApiEndpoints(builder, rolesApi);
        registerApiEndpoints(builder, estatusApi);
        registerApiEndpoints(builder, usuariosApi);
        registerApiEndpoints(builder, empresasApi);
        registerApiEndpoints(builder, rolesModulosApi);
        registerApiEndpoints(builder, usuariosModulosApi);
        registerApiEndpoints(builder, turnosApi);
        registerApiEndpoints(builder, marcasApi);
        registerApiEndpoints(builder, prioridadesApi);
        registerApiEndpoints(builder, unidadesMedidasApi);
        registerApiEndpoints(builder, departamentosApi);
        registerApiEndpoints(builder, categoriasApi);
        registerApiEndpoints(builder, configuracionesCorreosApi);
        registerApiEndpoints(builder, regimenesFiscalesApi);
        registerApiEndpoints(builder, protocolosCorreosApi);
        registerApiEndpoints(builder, tiposPersonasApi);
        registerApiEndpoints(builder, paisesApi);
        registerApiEndpoints(builder, entidadesFederativasApi);
        registerApiEndpoints(builder, municipiosApi);
        registerApiEndpoints(builder, categoriasProductosApi);
        registerApiEndpoints(builder, tiposProductosApi);
        registerApiEndpoints(builder, productosApi);
        registerApiEndpoints(builder, almacenesApi);
        registerApiEndpoints(builder, seccionesAlmacenesApi);
        registerApiEndpoints(builder, tiposSeccionesAlmacenesApi);
        registerApiEndpoints(builder, metodosSalidasAlmacenesApi);
        registerApiEndpoints(builder, metodosTrasladosAlmacenesApi);
        registerApiEndpoints(builder, pasillosAlmacenesApi);
        registerApiEndpoints(builder, estanteriasAlmacenesApi);
        registerApiEndpoints(builder, empleadosApi);
        registerApiEndpoints(builder, solicitudesReembolsosApi);
        registerApiEndpoints(builder, impuestosApi);
        registerApiEndpoints(builder, origenesEntradasApi);
        registerApiEndpoints(builder, origenesSalidasApi);
        registerApiEndpoints(builder, entradasApi);
        registerApiEndpoints(builder, entradasProductosApi);
        registerApiEndpoints(builder, salidasApi);
        registerApiEndpoints(builder, salidasProductosApi);
        registerApiEndpoints(builder, coloresApi);
        registerApiEndpoints(builder, inventariosApi);
        registerApiEndpoints(builder, configuracionesAlmacenesApi);
        registerApiEndpoints(builder, tiposTransportesApi);
        registerApiEndpoints(builder, tiposCombustiblesApi);
        registerApiEndpoints(builder, clasesTransportesApi);
        registerApiEndpoints(builder, marcasTransportesApi);
        registerApiEndpoints(builder, fabricantesTransportesApi);
        registerApiEndpoints(builder, transportesApi);
        registerApiEndpoints(builder, mantenimientosApi);
        registerApiEndpoints(builder, tiposMantenimientosApi);
        registerApiEndpoints(builder, mantenimientosPiezasApi);
        registerApiEndpoints(builder, localidadesApi);
        registerApiEndpoints(builder, tiposInstalacionesApi);
        registerApiEndpoints(builder, puestosApi);
        registerApiEndpoints(builder, tiposDocumentosApi);
        registerApiEndpoints(builder, configuracionesDocumentosApi);
        registerApiEndpoints(builder, diasFestivosApi);
        registerApiEndpoints(builder, vacacionesLeyApi)

        //PROCESOS

        registerApiEndpoints(builder, vacacionesApi);

    },
});

export default globalSlice.reducer;
