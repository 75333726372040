import {configureStore} from "@reduxjs/toolkit";
import globalReducer from "../slices/globalSlice"
import authReducer from '../slices/authSlice';
import perfilReducer from "../slices/perfilSlice"
import {authApi} from "../services/auth.service";
import {perfilApi} from "../services/perfil.service";
import {empresasApi} from "../services/empresa.service";
import {proyectosApi} from "../services/proyecto.service";
import {tiposMonedasApi} from "../services/tipoMoneda.service";
import {tiposPagosApi} from "../services/tipoPago.service";
import {solicitudesPagosApi} from "../services/solicitudPago.service";
import {estatusApi} from "../services/estatus.service";
import {observacionesApi} from "../services/observacion.service";
import {consultasApi} from "../services/consulta.service";
import {reportesApi} from "../services/reporte.service";
import {documentosApi} from "../services/documento.service";
import {archivosApi} from "../services/archivo.service";
import {bancosApi} from "../services/banco.service";
import {rolesApi} from "../services/rol.service";
import {usuariosApi} from "../services/usuario.service";
import {procesoEtapaDocumentoApi} from "../services/procesoEtapaDocumento.service";
import {rolesModulosApi} from "../services/rolModulo.service";
import {usuariosModulosApi} from "../services/usuarioModulo.service";
import {procesoEtapaCampoApi} from "../services/procesoEtapaCampo.service";
import {soporteApi} from "../services/soporte.service";
import {turnosApi} from "../services/turno.service";
import {marcasApi} from "../services/marca.service";
import {prioridadesApi} from "../services/prioridades.service";
import {unidadesMedidasApi} from "../services/unidadesMedidas.service";
import {departamentosApi} from "../services/departamento.service"
import {categoriasApi} from "../services/categoria.service"
import {configuracionesCorreosApi} from "../services/configuracionCorreo.service"
import {regimenesFiscalesApi} from "../services/regimenFiscal.service";
import {protocolosCorreosApi} from "../services/protocoloCorreo.service";
import {tiposPersonasApi} from "../services/tipoPersona.service";
import {tiposProductosApi} from "../services/tipoProducto.service";
import {categoriasProductosApi} from "../services/categoriaProducto.service";
import {productosApi} from "../services/producto.service";
import {municipiosApi} from "../services/municipio.service";
import {tiposPersonasBaseApi} from "../services/tipoPersonaBase.service";
import {entidadesFederativasApi} from "../services/entidadFederativa.service";
import {paisesApi} from "../services/pais.service";
import {almacenesApi} from "../services/almacen.service";
import {seccionesAlmacenesApi} from "../services/seccionAlmacen.service";
import {tiposSeccionesAlmacenesApi} from "../services/tipoSeccionAlmacen.service";
import {metodosSalidasAlmacenesApi} from "../services/metodoSalidaAlmacen.service";
import {metodosTrasladosAlmacenesApi} from "../services/metodoTrasladoAlmacen.service";
import {pasillosAlmacenesApi} from "../services/pasilloAlmacen.service";
import {estanteriasAlmacenesApi} from "../services/estanteriaAlmacen.service";
import {empleadosApi} from "../services/empleado.service";
import { solicitudesReembolsosApi } from "../services/solicitudReembolso.service";
import {impuestosApi} from "../services/impuesto.service";
import {origenesEntradasApi} from "../services/origenEntrada.service";
import {salidasApi} from "../services/salida.service";
import {origenesSalidasApi} from "../services/origenSalida.service";
import {entradasApi} from "../services/entrada.service";
import {entradasProductosApi} from "../services/entradaProducto.service";
import {salidasProductosApi} from "../services/salidaProducto.service";
import {coloresApi} from "../services/color.service";
import {inventariosApi} from "../services/inventario.service";
import {configuracionesAlmacenesApi} from "../services/configuracionAlmacen.service";
import {tiposTransportesApi} from "../services/tipoTransporte.service";
import {tiposCombustiblesApi} from "../services/tipoCombustible.service";
import {clasesTransportesApi} from "../services/claseTransporte.service";
import {marcasTransportesApi} from "../services/marcaTransporte.service";
import {fabricantesTransportesApi} from "../services/fabricanteTransporte.service";
import {transportesApi} from "../services/transporte.service";
import {mantenimientosApi} from "../services/mantenimiento.service";
import {tiposMantenimientosApi} from "../services/tipoMantenimiento.service";
import {mantenimientosPiezasApi} from "../services/mantenimientoPieza.service";
import { localidadesApi } from "../services/localidad.service";
import {tiposInstalacionesApi} from "../services/tipoInstalacion.service";
import {puestosApi} from "../services/puesto.service";
import {tiposDocumentosApi} from "../services/tipoDocumento.service";
import { configuracionesDocumentosApi } from "../services/configuracionDocumento.service";
import { vacacionesApi } from "../services/vacacion.service";
import {diasFestivosApi} from "../services/diasFestivos.service";
import { vacacionesLeyApi } from "../services/vacacionLey.service";

const store = configureStore({
    reducer: {
        global: globalReducer,
        auth: authReducer,
        perfil: perfilReducer,
        [authApi.reducerPath]: authApi.reducer,
        [rolesApi.reducerPath]: rolesApi.reducer,
        [perfilApi.reducerPath]: perfilApi.reducer,
        [usuariosApi.reducerPath]: usuariosApi.reducer,
        [bancosApi.reducerPath]: bancosApi.reducer,
        [estatusApi.reducerPath]: estatusApi.reducer,
        [archivosApi.reducerPath]: archivosApi.reducer,
        [empresasApi.reducerPath]: empresasApi.reducer,
        [reportesApi.reducerPath]: reportesApi.reducer,
        [proyectosApi.reducerPath]: proyectosApi.reducer,
        [consultasApi.reducerPath]: consultasApi.reducer,
        [documentosApi.reducerPath]: documentosApi.reducer,
        [tiposPagosApi.reducerPath]: tiposPagosApi.reducer,
        [tiposMonedasApi.reducerPath]: tiposMonedasApi.reducer,
        [rolesModulosApi.reducerPath]: rolesModulosApi.reducer,
        [observacionesApi.reducerPath]: observacionesApi.reducer,
        [usuariosModulosApi.reducerPath]: usuariosModulosApi.reducer,
        [solicitudesPagosApi.reducerPath]: solicitudesPagosApi.reducer,
        [procesoEtapaCampoApi.reducerPath]: procesoEtapaCampoApi.reducer,
        [procesoEtapaDocumentoApi.reducerPath]: procesoEtapaDocumentoApi.reducer,
        [soporteApi.reducerPath]: soporteApi.reducer,
        [turnosApi.reducerPath]: turnosApi.reducer,
        [marcasApi.reducerPath]: marcasApi.reducer,
        [prioridadesApi.reducerPath]: prioridadesApi.reducer,
        [unidadesMedidasApi.reducerPath]: unidadesMedidasApi.reducer,
        [departamentosApi.reducerPath]: departamentosApi.reducer,
        [categoriasApi.reducerPath]: categoriasApi.reducer,
        [configuracionesCorreosApi.reducerPath]: configuracionesCorreosApi.reducer,
        [regimenesFiscalesApi.reducerPath]: regimenesFiscalesApi.reducer,
        [protocolosCorreosApi.reducerPath]: protocolosCorreosApi.reducer,
        [categoriasProductosApi.reducerPath]: categoriasProductosApi.reducer,
        [tiposPersonasApi.reducerPath]: tiposPersonasApi.reducer,
        [tiposProductosApi.reducerPath]: tiposProductosApi.reducer,
        [productosApi.reducerPath]: productosApi.reducer,
        [municipiosApi.reducerPath]: municipiosApi.reducer,
        [tiposPersonasBaseApi.reducerPath]: tiposPersonasBaseApi.reducer,
        [entidadesFederativasApi.reducerPath]: entidadesFederativasApi.reducer,
        [paisesApi.reducerPath]: paisesApi.reducer,
        [almacenesApi.reducerPath]: almacenesApi.reducer,
        [seccionesAlmacenesApi.reducerPath]: seccionesAlmacenesApi.reducer,
        [tiposSeccionesAlmacenesApi.reducerPath]: tiposSeccionesAlmacenesApi.reducer,
        [metodosSalidasAlmacenesApi.reducerPath]: metodosSalidasAlmacenesApi.reducer,
        [metodosTrasladosAlmacenesApi.reducerPath]: metodosTrasladosAlmacenesApi.reducer,
        [pasillosAlmacenesApi.reducerPath]: pasillosAlmacenesApi.reducer,
        [estanteriasAlmacenesApi.reducerPath]: estanteriasAlmacenesApi.reducer,
        [empleadosApi.reducerPath]: empleadosApi.reducer,
        [solicitudesReembolsosApi.reducerPath]: solicitudesReembolsosApi.reducer,
        [impuestosApi.reducerPath]: impuestosApi.reducer,
        [origenesEntradasApi.reducerPath]: origenesEntradasApi.reducer,
        [origenesSalidasApi.reducerPath]: origenesSalidasApi.reducer,
        [entradasApi.reducerPath]: entradasApi.reducer,
        [entradasProductosApi.reducerPath]: entradasProductosApi.reducer,
        [salidasApi.reducerPath]: salidasApi.reducer,
        [salidasProductosApi.reducerPath]: salidasProductosApi.reducer,
        [coloresApi.reducerPath]: coloresApi.reducer,
        [inventariosApi.reducerPath]: inventariosApi.reducer,
        [configuracionesAlmacenesApi.reducerPath]: configuracionesAlmacenesApi.reducer,
        [tiposTransportesApi.reducerPath]: tiposTransportesApi.reducer,
        [tiposCombustiblesApi.reducerPath]: tiposCombustiblesApi.reducer,
        [clasesTransportesApi.reducerPath]: clasesTransportesApi.reducer,
        [marcasTransportesApi.reducerPath]: marcasTransportesApi.reducer,
        [fabricantesTransportesApi.reducerPath]: fabricantesTransportesApi.reducer,
        [transportesApi.reducerPath]: transportesApi.reducer,
        [mantenimientosApi.reducerPath]: mantenimientosApi.reducer,
        [tiposMantenimientosApi.reducerPath]: tiposMantenimientosApi.reducer,
        [mantenimientosPiezasApi.reducerPath]: mantenimientosPiezasApi.reducer,
        [localidadesApi.reducerPath]: localidadesApi.reducer,
        [tiposInstalacionesApi.reducerPath]: tiposInstalacionesApi.reducer,
        [puestosApi.reducerPath]: puestosApi.reducer,
        [vacacionesApi.reducerPath]: vacacionesApi.reducer,
        [diasFestivosApi.reducerPath]: diasFestivosApi.reducer,
        
        [vacacionesLeyApi.reducerPath]: vacacionesLeyApi.reducer,
        [tiposDocumentosApi.reducerPath]: tiposDocumentosApi.reducer,
        [configuracionesDocumentosApi.reducerPath]: configuracionesDocumentosApi.reducer,


    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authApi.middleware)
            .concat(rolesApi.middleware)
            .concat(perfilApi.middleware)
            .concat(usuariosApi.middleware)
            .concat(bancosApi.middleware)
            .concat(estatusApi.middleware)
            .concat(archivosApi.middleware)
            .concat(empresasApi.middleware)
            .concat(reportesApi.middleware)
            .concat(proyectosApi.middleware)
            .concat(consultasApi.middleware)
            .concat(documentosApi.middleware)
            .concat(tiposPagosApi.middleware)
            .concat(tiposMonedasApi.middleware)
            .concat(rolesModulosApi.middleware)
            .concat(observacionesApi.middleware)
            .concat(usuariosModulosApi.middleware)
            .concat(solicitudesPagosApi.middleware)
            .concat(procesoEtapaCampoApi.middleware)
            .concat(procesoEtapaDocumentoApi.middleware)
            .concat(soporteApi.middleware)
            .concat(turnosApi.middleware)
            .concat(marcasApi.middleware)
            .concat(prioridadesApi.middleware)
            .concat(unidadesMedidasApi.middleware)
            .concat(categoriasApi.middleware)
            .concat(departamentosApi.middleware)
            .concat(configuracionesCorreosApi.middleware)
            .concat(regimenesFiscalesApi.middleware)
            .concat(protocolosCorreosApi.middleware)
            .concat(tiposPersonasApi.middleware)
            .concat(categoriasProductosApi.middleware)
            .concat(tiposProductosApi.middleware)
            .concat(productosApi.middleware)
            .concat(municipiosApi.middleware)
            .concat(tiposPersonasBaseApi.middleware)
            .concat(entidadesFederativasApi.middleware)
            .concat(paisesApi.middleware)
            .concat(almacenesApi.middleware)
            .concat(seccionesAlmacenesApi.middleware)
            .concat(tiposSeccionesAlmacenesApi.middleware)
            .concat(metodosSalidasAlmacenesApi.middleware)
            .concat(metodosTrasladosAlmacenesApi.middleware)
            .concat(pasillosAlmacenesApi.middleware)
            .concat(estanteriasAlmacenesApi.middleware)
            .concat(empleadosApi.middleware)
            .concat(solicitudesReembolsosApi.middleware)
            .concat(impuestosApi.middleware)
            .concat(origenesEntradasApi.middleware)
            .concat(origenesSalidasApi.middleware)
            .concat(entradasApi.middleware)
            .concat(entradasProductosApi.middleware)
            .concat(salidasApi.middleware)
            .concat(salidasProductosApi.middleware)
            .concat(coloresApi.middleware)
            .concat(inventariosApi.middleware)
            .concat(configuracionesAlmacenesApi.middleware)
            .concat(tiposTransportesApi.middleware)
            .concat(tiposCombustiblesApi.middleware)
            .concat(clasesTransportesApi.middleware)
            .concat(marcasTransportesApi.middleware)
            .concat(fabricantesTransportesApi.middleware)
            .concat(transportesApi.middleware)
            .concat(transportesApi.middleware)
            .concat(mantenimientosApi.middleware)
            .concat(tiposMantenimientosApi.middleware)
            .concat(mantenimientosPiezasApi.middleware)
            .concat(localidadesApi.middleware)
            .concat(tiposInstalacionesApi.middleware)
            .concat(puestosApi.middleware)
            .concat(tiposDocumentosApi.middleware)
            .concat(configuracionesDocumentosApi.middleware)
            .concat(vacacionesApi.middleware)
            .concat(diasFestivosApi.middleware)
            .concat(vacacionesLeyApi.middleware)
         

});

export default store;