import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import VacacionesForm from "../../views/procesos/vacaciones/VacacionesForm";
import ConfirmDialog from "../base/ConfirmDialog";
import { Link } from "react-router-dom";

const VacacionCard = ({ vacacion, refetch, eliminarVacacion }) => {

    const [vistaVacaciones, setVistaVacaciones] = useState(null)
    const [confirmDialog, setConfirmDialog] = useState({})

    return (
        <Card className={`mb-0 mt-3`}>
            <Card.Header className={`pb-0 mb-0 fw-bold alert alert-primary`}>
                <h5 className="card-title pt-1 pb-0 text-primary">
                    {vacacion.empleado.nombreCustom}{" "}
                </h5>
            </Card.Header>

            <div className="row card-body pt-2">
                <p className="card-text mb-1">
                    <span className="text-muted">
                        Fecha de solicitud: <b>{vacacion.fechaSolicitud?.split(" ")[0]}</b>
                    </span>{" "}
                    | Estatus: <b>{vacacion.estatus.estatus}</b>
                </p>


                <div className="col-md-12 mb-2 pb-0">
                    <p className="card-text mb-1">
                        <strong>Empresa: </strong>
                        {vacacion.empresa.empresa}
                    </p>

                    <p className="card-text mb-1">
                        <strong>Fechas solicitadas: </strong>
                        {vacacion.fechaInicioSol}
                        <span className="mx-4">-</span>
                        {vacacion.fechaFinalSol}
                    </p>
                    
                    <p className="card-text mb-1">
                        <strong>Fechas autorizadas: </strong>
                        {vacacion.fechaInicioAuth} 
                         <span className="mx-4">-</span>
                        {vacacion.fechaFinalAuth}
                    </p>

                    <p className="card-text mb-1">
                        <strong>Días autorizados: </strong>
                        {vacacion.diasAutorizados}
                    </p>
                </div>

                <div className="d-inline mb-2">
                    <Link className="btn btn-sm btn-outline-primary me-2 mb-2 py-0"
                        data-bs-toggle="tooltip" data-bs-title="Visualizar Expediente"
                        to={"/solicitudes/vacaciones/view/" + vacacion.idVacacion}>
                        <i className="bi bi-file-text fs-5"></i> Visualizar solicitud
                    </Link>
                </div>

            </div>

            {/* <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})} /> */}

            {vistaVacaciones && (
                <VacacionesForm
                    show={vistaVacaciones}
                    onHide={(e) => {
                        setVistaVacaciones(false)
                    }}
                    onSave={refetch}
                />
            )}
        </Card>
    )
}



export default VacacionCard