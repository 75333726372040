import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { Alert, Button, Card, Form, Row, Col } from "react-bootstrap";
import {
    useGuardarVacacionMutation,
    useObtenerVacacionQuery
} from '../../../services/vacacion.service'
import { useObtenerEstatusPorTipoQuery } from "../../../services/estatus.service";
import { useObtenerEmpresasEmpleadosQuery } from "../../../services/empresa.service";
import { Link, useParams } from "react-router-dom";
import FormLabelCounter from "../../../components/base/FormLabelCounter";
import { toast } from "react-toastify";
import _, { set } from "lodash";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { useObtenerEmpleadoQuery } from '../../../services/empleado.service'


const VacacionesView = () => {

    const { id: idVacacion } = useParams()

    const {
        control,
        handleSubmit,
        setValue,
        register,
        reset,
        watch,
        formState: { errors },
    } = useForm();

    const {
        data: vacacion,
        refetch: refetchVacacion
    } = useObtenerVacacionQuery(idVacacion, {
        skip: !idVacacion,
        refetchOnMountOrArgChange: true
    })

    const { data: estatusVacacion } = useObtenerEstatusPorTipoQuery(3)
    const { data: empresas } = useObtenerEmpresasEmpleadosQuery();
    const [guardarVacacion] = useGuardarVacacionMutation()

    const [idEmpresa, setIdEmpresa] = useState(0);
    const [idEstatusV, setIdEstatusV] = useState(0)

    const [diasAutorizados, setDiasAutorizados] = useState(0);
    const [nombreEmpleado, setNombreEmpleado] = useState('')
    const [fechaSolicitud, setFechaSolicitud] = useState('')
    const [fechaInicioSol, setFechaInicioSol] = useState('')
    const [fechaFinalSol, setFechaFinalSol] = useState('')
    const [fechaInicioAuth, setFechaInicioAuth] = useState('');
    const [fechaFinalAuth, setFechaFinalAuth] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({});

    const calculateDaysDifference = (startDate, endDate) => {
        if (startDate && endDate) {
            const start = dayjs(startDate, "DD/MM/YYYY");
            const end = dayjs(endDate, "DD/MM/YYYY");
            const difference = end.diff(start, 'day') + 1; // Add 1 to include both start and end dates
            console.log("Days Difference:", difference);
            setDiasAutorizados(difference > 0 ? difference : 0);
        } else {
            setDiasAutorizados(0);
        }
    };

    useEffect(() => {
        if (vacacion) {
            reset({
                idVacacion: vacacion.idVacacion,
                idEmpresa: vacacion.idEmpresa,
                idEstatus: vacacion.idEstatus,
                idVacacionLey: vacacion.idVacacionLey,
                fechaInicioSol: vacacion.fechaInicioSol,
                fechaFinalSol: vacacion.fechaFinalSol,
                fechaInicioAuth: vacacion.fechaInicioAuth,
                fechaFinalAuth: vacacion.fechaFinalAuth,
                diasSolicitados: vacacion.diasSolicitados,
                diasAutorizados: vacacion.diasAutorizados,
                observaciones: vacacion.observaciones,
                fechaVersion: vacacion.fechaVersion
            })

            const {
                fechaSolicitud,
                empleado,
                idEmpresa,
                diasAutorizados,
            } = vacacion

            if (empresas)
                setIdEmpresa(idEmpresa);

            setFechaSolicitud(fechaSolicitud);
            setNombreEmpleado(empleado.nombreCustom);
            setFechaInicioSol(fechaInicioSol)
            setFechaFinalSol(fechaFinalSol)
            setDiasAutorizados(diasAutorizados)
            console.log(empresas)

        }
    }, [vacacion, reset, empresas])


    const onSubmit = async (formData) => {
        let data
        formData.idVacacion = parseInt(idVacacion)
        formData.idEstatus = parseInt(formData.idEstatus)
        formData.diasAutorizados = diasAutorizados
        if (formData.fechaInicioAuth == null && formData.fechaFinalAuth == null) {
            formData.fechaInicioAuth = formData.fechaInicioSol
            formData.fechaFinalAuth = formData.fechaFinalSol
        }
        let result = await guardarVacacion(formData)
        data = result.data
    }

    return (<>
        <main id="main" className="main">
            <section className="section">
                <Card>
                    <Card.Body>
                        <fieldset>
                            <legend className="d-flex flex-column flex-md-row justify-content-between align-items-right">
                                <div className="fw-bold">
                                    <h2> Solicitud de vacaciones </h2>
                                </div>
                                <div>
                                    <p className="fs-6 mb-0">
                                        *Los campos en negritas son requeridos{" "}
                                    </p>
                                </div>
                            </legend>
                        </fieldset>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Control
                                type="text"
                                id="idVacacion"
                                className="d-none"
                                {...register("idVacacion")}
                            />

                            <Form.Control
                                type="text"
                                id="fechaVersion"
                                className="d-none"
                                {...register("fechaVersion")}
                            />

                            <Card>
                                <div className="container">
                                    <div className="row">

                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="nombreEmpleado">
                                                Nombre de empleado
                                            </Form.Label>

                                            <Form.Control
                                                value={nombreEmpleado}
                                                className="mwx-400"
                                                type='text'
                                                disabled
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="idEmpresa" className="fw-bold">
                                                Empresa
                                            </Form.Label>
                                            <Form.Select
                                                className="mwx-400"
                                                value={idEmpresa}
                                                id="idEmpresa"
                                                disabled
                                                {...register("idEmpresa", {
                                                    required: "Ingrese un dato válido",
                                                    onChange: (event) => {
                                                        setIdEmpresa(event.target.value);
                                                    },
                                                })}
                                            >
                                                <option value="">Seleccionar</option>
                                                {empresas &&
                                                    empresas.map((empresa) => (
                                                        <option key={'empresa.' + empresa.idEmpresa}
                                                            value={empresa.idEmpresa}>
                                                            {empresa.empresa}
                                                        </option>
                                                    ))}
                                            </Form.Select>

                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="fechaSolicitud">
                                                Fecha de solicitud
                                            </Form.Label>
                                            <Form.Control
                                                //{...register("fechaSolicitud")}
                                                type="text"
                                                className="mwx-200"
                                                id="fechaSolicitud"
                                                disabled
                                                value={fechaSolicitud}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="fechaInicioSol">
                                                Fecha inicio solicitada
                                            </Form.Label>
                                            <Form.Control
                                                {...register("fechaInicioSol")}
                                                type="text"
                                                className="mwx-200"
                                                id="fechaInicioSol"
                                                disabled
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="fechaFinalSol">
                                                Fecha final solicitada
                                            </Form.Label>
                                            <Form.Control
                                                {...register("fechaFinalSol")}
                                                type="text"
                                                id="fechaFinalSol"
                                                className="mwx-200"
                                                disabled
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="fechaInicioAuth">
                                                Fecha inicio autorizada
                                            </Form.Label>
                                            <div>
                                                <Controller
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            className="form-control mwx-200"
                                                            id="fechaInicioAuth"
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={field.value ? dayjs(field.value, "DD/MM/YYYY").toDate() : null}
                                                            onChange={(date) => {
                                                                const formattedDate = dayjs(date).format("DD/MM/YYYY");
                                                                field.onChange(formattedDate);
                                                                setFechaInicioAuth(formattedDate);
                                                                calculateDaysDifference(formattedDate, fechaFinalAuth);
                                                            }}
                                                        />
                                                    )}
                                                    name="fechaInicioAuth"
                                                    control={control}
                                                //rules={{ required: "Ingrese un dato válido" }}
                                                />
                                                {/* {errors.fechaInicioAuth && (<Alert variant="danger" role="alert">
                                                    {errors.fechaInicioAuth.message}</Alert>)} */}
                                            </div>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="fechaFinalAuth" >
                                                Fecha final autorizada
                                            </Form.Label>
                                            <div>

                                                <Controller
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            className="form-control mwx-200"
                                                            id="fechaFinalAuth"
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={field.value ? dayjs(field.value, "DD/MM/YYYY").toDate() : null}
                                                            onChange={(date) => {
                                                                const formattedDate = dayjs(date).format("DD/MM/YYYY");
                                                                field.onChange(formattedDate);
                                                                setFechaFinalAuth(formattedDate);
                                                                calculateDaysDifference(fechaInicioAuth, formattedDate);
                                                            }}
                                                        />
                                                    )}
                                                    name="fechaFinalAuth"
                                                    control={control}
                                                //rules={{ required: "Ingrese un dato válido" }}
                                                />
                                            </div>
                                            {/* {errors.fechaFinalAuth && (<Alert variant="danger" role="alert">
                                                {errors.fechaFinalAuth.message} 
                                            </Alert>)}*/}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="diasAutorizados" className="fw-bold">
                                                Días autorizados
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="mwx-200"
                                                id="diasAutorizados"

                                                value={diasAutorizados}
                                                readOnly
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label htmlFor="idEstatus" className='fw-bold'>
                                                Estatus
                                            </Form.Label>

                                            <Form.Select
                                                id='idEstatus'
                                                className="mwx-200"
                                                {...register("idEstatus", {
                                                    required: "Ingrese un dato válido"
                                                })}
                                            >
                                                <option value="">Seleccionar</option>
                                                {estatusVacacion && estatusVacacion.map((estatus) => (
                                                    <option key={'estatus.' + estatus.idEstatus}
                                                        value={estatus.idEstatus}>
                                                        {estatus.estatus}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <FormLabelCounter
                                                htmlFor="observaciones"
                                                className="mwx-400 "
                                                input={watch('observaciones')}
                                                maxLength={4000}
                                            >
                                                Observaciones
                                            </FormLabelCounter>
                                            <Form.Control
                                                as="textarea"
                                                rows="4"
                                                className="mwx-400"
                                                id="observaciones"
                                                {...register('observaciones', {
                                                    //required: 'Ingrese un dato válido',
                                                    maxLength: { value: 4000, message: 'Ha excedido el máximo de caracteres' }
                                                })}
                                            />
                                        </Form.Group>

                                    </div>
                                </div>
                            </Card>


                            <div className="d-grid gap-2 d-md-block">
                                <Link className="btn btn-secondary me-md-3 me-2"
                                    to={"/solicitudes/vacaciones"}>
                                    <i className="bi bi-box-arrow-in-left"></i> Regresar
                                </Link>
                                <Button type="submit" variant="primary" className="me-2">
                                    <i className="bi bi-save"></i> Guardar
                                </Button>
                            </div>


                        </Form>
                    </Card.Body>
                </Card >
            </section >
        </main >
    </>)
}

export default VacacionesView 