import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import _, { inRange } from "lodash";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { useObtenerEmpresasEmpleadosQuery } from '../../../services/empresa.service';
import { useObtenerEstatusEtapaQuery } from "../../../services/estatus.service";
import { useObtenerVacacionQuery } from "../../../services/vacacion.service";
import { useGuardarVacacionMutation } from "../../../services/vacacion.service";

const VacacionesForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { userInfo } = useSelector((state) => state.auth);
    const {
        control,
        register,
        reset,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm();

    const { id: idVacacion } = useParams();

    const {
        data: vacacion,
        refetch: refetchVacacion
    } = useObtenerVacacionQuery({ idVacacion }, { skip: !idVacacion, refetchOnMountOrArgChange: true });

    const [guardarVacacion] = useGuardarVacacionMutation();

    const [idEmpresa, setIdEmpresa] = useState(0);
    const [idEmpleado, setIdEmpleado] = useState(userInfo.userId);
    const [estatus, setEstatus] = useState('');
    const [fechaSolicitud, setFechaSolicitud] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({});
    const [fechaIngreso, setFechaIngreso] = useState('');
    const [fechaInicioSol, setFechaInicioSol] = useState('');
    const [fechaFinalSol, setFechaFinalSol] = useState('');
    const [diasSolicitados, setDiasSolicitados] = useState(0);
    const [formObservaciones, setFormObservaciones] = useState(null);

    const calculateDaysDifference = (startDate, endDate) => {
        console.log("Start Date:", startDate);
        console.log("End Date:", endDate);
        if (startDate && endDate) {
            const start = dayjs(startDate, "DD/MM/YYYY");
            const end = dayjs(endDate, "DD/MM/YYYY");
            const difference = end.diff(start, 'day') + 1; // Add 1 to include both start and end dates
            console.log("Days Difference:", difference);
            setDiasSolicitados(difference > 0 ? difference : 0);
        } else {
            setDiasSolicitados(0);
        }
    };


    const [nombreEmpleado, setNombreEmpleado] = useState(userInfo.userName);

    const { data: empresas } = useObtenerEmpresasEmpleadosQuery();

    useEffect(() => {
        if (vacacion) {
            const {
                idEmpleado,
                idEmpresa,
                idEstatus,
                idVacacionLey,
                fechaSolicitud,
                fechaInicioSol,
                fechaFinalSol,
                diasSolicitados,
                diasAutorizados,
                observaciones,
                usuario,
                fechaVersion,
                estatus,
                empleado
                
            } = vacacion;

            if (empresas)
                setIdEmpresa(idEmpresa);
            
            setEstatus(estatus.estatus);
            setFechaSolicitud(fechaSolicitud);
            setNombreEmpleado(empleado.nombreCustom);
            
        }
    }, [reset, setValue, vacacion, empresas]);

    const onSubmit = async (formData) => {
        let data;
        formData.idVacacion = parseInt(idVacacion)
        formData.diasSolicitados = diasSolicitados
        let result = await guardarVacacion(formData);
        data = result.data;

    };

    return (
        <>
            <main id="main" className="main">
                <section className="section">
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <fieldset>
                                        <legend>
                                            <h2>NUEVA [SOLICITUD DE VACACIONES]</h2>
                                            <h6> *Los campos en negritas son requeridos</h6>
                                        </legend>
                                    </fieldset>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        {/* Cambiar esto a los ids que voy a ocupar al parecer se quedan asi*/}
                                        <Form.Control type="text" id="idVacacion" className="d-none" defaultValue={idVacacion} {...register("idVacacion")} />
                                        <Form.Control type="text" id="idEstatus" className="d-none" {...register("idEstatus")} />
                                        <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />

                                        {/* Si recupera el nombre del empleado */}
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="nombreEmpleado">Nombre Empleado</Form.Label>
                                                <Form.Control type="text" className="mwx-400" id="nombreEmpleado" disabled
                                                    value={nombreEmpleado} />
                                            </Col>
                                        </Row>
                                        {/* Se busca empresa */}
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="idEmpresa" className="fw-bold">Empresa</Form.Label>
                                                <Form.Select
                                                    className="mwx-400"
                                                    value={idEmpresa}
                                                    id="idEmpresa"
                                                    disabled=""
                                                    {...register("idEmpresa", {
                                                        required: "Ingrese un dato válido",
                                                        onChange: (event) => {
                                                            setIdEmpresa(event.target.value);
                                                        },
                                                    })}
                                                >
                                                    <option value="">Seleccionar</option>
                                                    {empresas &&
                                                        empresas.map((empresa) => (
                                                            <option key={'empresa.' + empresa.idEmpresa}
                                                                value={empresa.idEmpresa}>
                                                                {empresa.empresa}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                                {errors.idEmpresa && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.idEmpresa.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>

                                        {/* Fecha de ingreso */}
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="fechaIngreso">Fecha de ingreso</Form.Label>
                                                <Form.Control type="text" className="mwx-200" id="fechaIngreso" disabled
                                                    value={fechaIngreso} />
                                            </Col>
                                        </Row >

                                        {/* Fecha incial */}
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="fechaInicioSol" className="fw-bold">Fecha inicio </Form.Label>
                                                <div>
                                                    <Controller
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                className="form-control"
                                                                id="fechaInicioSol"
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={field.value ? dayjs(field.value, "DD/MM/YYYY").toDate() : null}
                                                                onChange={(date) => {
                                                                    const formattedDate = dayjs(date).format("DD/MM/YYYY");
                                                                    field.onChange(formattedDate);
                                                                    setFechaInicioSol(formattedDate);
                                                                    calculateDaysDifference(formattedDate, fechaFinalSol);
                                                                }}
                                                            />
                                                        )}
                                                        name="fechaInicioSol"
                                                        control={control}
                                                        rules={{ required: "Ingrese un dato válido" }}
                                                    />
                                                    {errors.fechaInicioSol && (<Alert variant="danger" role="alert">
                                                        {errors.fechaInicioSol.message}
                                                    </Alert>)}
                                                </div>
                                            </Col>
                                        </Row>

                                        {/* Fecha final */}
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="fechaFinalSol" className="fw-bold">Fecha fin</Form.Label>
                                                <div>
                                                    <Controller
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                className="form-control"
                                                                id="fechaFinalSol"
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={field.value ? dayjs(field.value, "DD/MM/YYYY").toDate() : null}
                                                                onChange={(date) => {
                                                                    const formattedDate = dayjs(date).format("DD/MM/YYYY");
                                                                    field.onChange(formattedDate);
                                                                    setFechaFinalSol(formattedDate);
                                                                    calculateDaysDifference(fechaInicioSol, formattedDate);
                                                                }}
                                                            />
                                                        )}
                                                        name="fechaFinalSol"
                                                        control={control}
                                                        rules={{ required: "Ingrese un dato válido" }}
                                                    />
                                                </div>
                                                {errors.fechaFinalSol && (<Alert variant="danger" role="alert">
                                                    {errors.fechaFinalSol.message}
                                                </Alert>)}
                                            </Col>
                                        </Row>

                                        {/* Diferencia de días */}
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="diasSolicitados" className="fw-bold">Días</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="mwx-200"
                                                    id="diasSolicitados"
                                                    value={diasSolicitados}
                                                    readOnly
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        {/* Botones */}
                                        <Row className="mt-3">
                                            <Col md={12}>
                                                <div className="d-grid gap-2 d-md-block">
                                                    <Link className="btn btn-secondary me-md-3" to={"/solicitudes/vacaciones"}>
                                                        <i className="bi bi-box-arrow-in-left"></i> Regresar
                                                    </Link>

                                                    <Button type="submit" variant="primary" className="me-2">
                                                        <i className="bi bi-save"></i> Guardar
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
        </>
    )
}

export default VacacionesForm;