import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Alert, Button, Form, Modal } from "react-bootstrap"
import { useGuardarDiaFestivoMutation, useObtenerDiaFestivoQuery } from "../../../services/diasFestivos.service"
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

const DiasFestivosForm = ({ show, onHide, onSave }) => {

    const handleClose = (e) => {
        onHide(e)
    }

    const { control, handleSubmit, setValue, register, reset, formState: { errors } } = useForm()

    const { data: diaFestivo } = useObtenerDiaFestivoQuery(show.idDiaFestivo, { skip: !show.idDiaFestivo, refetchOnMountOrArgChange: true })
    const [guardarDiaFestivo] = useGuardarDiaFestivoMutation()

    useEffect(() => {
        if (diaFestivo) {
            reset({
                idDiaFestivo: diaFestivo.idDiaFestivo,
                diaFestivo: diaFestivo.diaFestivo,
                fecha:diaFestivo.fecha,
                idEstatus: diaFestivo.idEstatus,
                fechaVersion: diaFestivo.fechaVersion
            })
        } else {
            //default values
            reset({ idEstatus: 1 })
        }
    }, [diaFestivo, reset])

    const onSubmit = async (formData) => {
        let { data } = await guardarDiaFestivo(formData)
        if (data) {
            setValue('idDiaFestivo', data.id)
            setValue('fechaVersion', data.fechaVersion)
            onSave()
        }
    }

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.idDiaFestivo ? 'Editar' : 'Nuevo'} [ Dia Festivo ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idDiaFestivo" className="d-none" {...register("idDiaFestivo")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="diaFestivo" className="fw-bold">
                            Dia Festivo
                        </Form.Label>
                        <Form.Control
                            {...register('diaFestivo', {
                                required: 'Ingrese un dato válido',
                                maxLength: { value: 50, message: 'Ha excedido el máximo de caracteres' }
                            })}
                        />
                        {errors.diaFestivo && (<Alert variant="danger" role="alert">
                            {errors.diaFestivo.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="fecha" className="fw-bold">
                            Fecha
                        </Form.Label>
                        <div>
                            <Controller
                                render={({ field }) => (
                                    <DatePicker
                                        className="form-control"
                                        id="fecha"
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            field.value
                                                ? dayjs(field.value, "DD/MM/YYYY").toDate()
                                                : null
                                        }
                                        onChange={(date) => {
                                            field.onChange(dayjs(date).format("DD/MM/YYYY"));
                                        }}
                                    />
                                )}
                                name="fecha"
                                control={control}
                                rules={{ required: "Ingrese un dato válido" }}
                            />
                            {errors.fecha && (
                                <Alert variant="danger" role="alert">
                                    {errors.fecha.message}
                                </Alert>
                            )}
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({ field }) => (<>
                                <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                <Form.Check
                                    id="idEstatus"
                                    type="switch"
                                    label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                    checked={Boolean(field.value)}
                                    onChange={(e) => {
                                        field.onChange(Number(e.target.checked))
                                    }}
                                />
                            </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>)
}
export default DiasFestivosForm