import {
    useObtenerDiasFestivosQuery,
    useEliminarDiaFestivoMutation,
    useLazyObtenerDiasFestivosQuery
} from '../../../services/diasFestivos.service';
import React, { useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import DiasFestivosForm from './DiasFestivosForm';
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import { ExportToExcel } from "../../../components/base/ExportToExcel";

const DiasFestivos = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formDiasFestivos, setFormDiasFestivos] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const {
        data: diasFestivos, refetch
    } = useObtenerDiasFestivosQuery([pageNumber, elementsPerPage], { refetchOnMountOrArgChange: true })
    const [trigger] = useLazyObtenerDiasFestivosQuery()

    const [eliminarDiaFestivo] = useEliminarDiaFestivoMutation()

    return (<div id="main" className="main">
        <section className="section">
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="btn-toolbar justify-content-between" role="toolbar">
                                <div className="pagetitle">
                                    <h1>Dias Festivos</h1>
                                </div>
                            </div>
                            {diasFestivos &&
                                <BootstrapTable striped
                                    toolbar={({ columns }) => (
                                        <div>
                                            <Button href="#" className="me-2" variant="primary" onClick={(e) => {
                                                setFormDiasFestivos({})
                                            }}>
                                                <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                            </Button>
                                            <ExportToExcel columns={columns} onClick={trigger} fileName={'diasFestivos'} />
                                        </div>
                                    )}
                                    data = {diasFestivos.data}
                                    columns={[{
                                        header: 'Dia Festivo', accessorKey: 'diaFestivo'
                                    },{
                                        header: 'Fecha', accessorKey: 'fecha'
                                    }, {
                                        header: 'Estatus', accessorKey: 'estatus.estatus', enableGlobalFilter: false
                                    }, {
                                        header: 'Acciones',
                                        enableGlobalFilter: false,
                                        cell: ({ row: { original } }) => (
                                            <div className="w-100 d-grid d-sm-block gap-1">
                                                <Button variant="warning" className="me-2" size="sm"
                                                    onClick={e => setFormDiasFestivos(original) }>
                                                    <i className="bi bi-pencil-square"> </i>
                                                    Editar
                                                </Button>
                                                <Button variant="danger" className="me-2" size="sm" onClick={e => setConfirmDialog({
                                                    message: '¿Desea eliminar este registro?', onConfirm: async () => {
                                                        await eliminarDiaFestivo({ idDiaFestivo: original.idDiaFestivo });
                                                        refetch();
                                                        setConfirmDialog({});

                                                    }
                                                })}>
                                                    <i className="bi bi-trash"> </i>
                                                    Eliminar
                                                </Button>
                                            </div>)
                                    }]}
                                    onGlobalFilterChange={(e => {
                                        setPageNumber(1);
                                        setElementsPerPage(e ? -1 : 10);
                                    })}
                                />}
                                {diasFestivos && <SeverPagination
                                    data={diasFestivos.data}
                                    currentPage={pageNumber}
                                    totalElements={diasFestivos.totalElements}
                                    elementsPerPage={elementsPerPage}
                                    onPageChange={setPageNumber}
                                    onElementsPerPageChange={setElementsPerPage}
                                /> }
                        </Card.Body>
                        <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})}/>
                    </Card>
                </Col>
            </Row>
        </section>
        {formDiasFestivos && <DiasFestivosForm show={formDiasFestivos} onHide={(e) => {
            setFormDiasFestivos(false)
        }} onSave={refetch}/>}
    </div> )
}

export default DiasFestivos