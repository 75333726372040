import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Button, Form, Modal } from "react-bootstrap";
import {
    useGuardarVacacionLeyMutation,
    useObtenerVacacionLeyQuery
} from '../../../services/vacacionLey.service';

const VacacionesLeyForm = ({ show, onHide, onSave }) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const { control,
        handleSubmit,
        setValue,
        register,
        reset,
        formState: { errors }
    } = useForm();

    const {
        data: vacacionLey
    } = useObtenerVacacionLeyQuery(show.idVacacionLey, { skip: !show.idVacacionLey, refetchOnMountOrArgChange: true })

    const [guadarVacacionLey] = useGuardarVacacionLeyMutation()

    useEffect(() => {
        if (vacacionLey) {
            reset({
                idVacacionLey: vacacionLey.idVacacionLey,
                idEstatus: vacacionLey.idEstatus,
                anio: vacacionLey.anio,
                anioInicial: vacacionLey.anioInicial,
                anioFinal: vacacionLey.anioFinal,
                diasVacaciones: vacacionLey.diasVacaciones,
                fechaVersion: vacacionLey.fechaVersion
            })
        } else {
            reset({ idEstatus: 1 })
        }
    }, [vacacionLey, reset])

    const onSubmit = async (formData) => {
        let { data } = await guadarVacacionLey(formData);
        if (data) {
            setValue('idVacacionLey', data.id)
            setValue('fechaVersion', data.fechaVersion)
            onSave();
        }
    }

    return (
        <>
            {
                < Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} >
                    <Form onSubmit={handleSubmit(onSubmit)} >
                        <Modal.Header closeButton>
                            <Modal.Title>{show.idVacacionLey ? 'Editar' : 'Nuevo'} [Vacacion ley]</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Control type="text" id="idVacacionLey" className='d-none' {...register("idVacacionLey")} />
                            <Form.Control type="text" id='fechaVersion' className='d-none' {...register("fechaVersion")} />
                            <Form.Group className='mb-3'>
                                <Form.Label htmlFor='anio' className='fw-bold'>
                                    Inicio vigencia 
                                </Form.Label>
                                <Form.Control
                                    {...register('anio', {
                                        required: 'Ingrese un dato valido',
                                        maxLength: { value: 4, message: 'Ha excedido el máximo de caracteres' }
                                    })} />
                                <Form.Label htmlFor='anioInicial' className='fw-bold'>
                                    Año inicial
                                </Form.Label>
                                <Form.Control
                                    {...register('anioInicial', {
                                        required: 'Ingrese un dato valido',
                                        maxLength: { value: 2, message: 'Ha excedido el máximo de caracteres' }
                                    })} />
                                <Form.Label htmlFor='anioFinal' className='fw-bold'>
                                    Año final
                                </Form.Label>
                                <Form.Control
                                    {...register('anioFinal', {
                                        required: 'Ingrese un dato valido',
                                        maxLength: { value: 2, message: 'Ha excedido el máximo de caracteres' }
                                    })} />
                                <Form.Label htmlFor='diasVacaciones' className='fw-bold'>
                                    Dias de vacaciones
                                </Form.Label>
                                <Form.Control
                                    {...register('diasVacaciones', {
                                        required: 'Ingrese un dato valido',
                                        maxLength: { value: 2, message: 'Ha excedido el máximo de caracteres' }
                                    })} />
                                {errors.vacacionLey && (<Alert variant='danger' role='alert'>
                                    {errors.vacacionLey.message}
                                </Alert>)}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Controller
                                    name='idEstatus'
                                    control={control}
                                    render={({ field }) => (<>
                                        <Form.Label htmlFor='idEstatus' > Estatus </Form.Label>
                                        <Form.Check
                                            id='idEstatus'
                                            type='switch'
                                            label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                            checked={Boolean(field.value)}
                                            onChange={(e) => {
                                                field.onChange(Number(e.target.checked))
                                            }}
                                        />
                                    </>
                                    )}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type='submit' variant='primary' className='me-2'>
                                <i className='bi bi-save'></i> Guardar
                            </Button>
                            <Button variant='secondary' onClick={handleClose}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

            }
        </>
    )

}

export default VacacionesLeyForm