import {
    useBuscarVacacionesQuery
} from '../../../services/vacacion.service'
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Card, Form } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination"
import VacacionCard from '../../../components/vacaciones/VacacionCard';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cleanProperties } from "../../../helpers/cleanProperties";
import { Controller, useForm } from "react-hook-form";
import VacacionesForm from './VacacionesForm';
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const Vacaciones = () => {
    const { control, register, reset, handleSubmit } = useForm();

    const [vistaVacaciones, setVistaVacaciones] = useState(null)

    const { state, pathname } = useLocation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useState({});

    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const { data: vacaciones, refetch } = useBuscarVacacionesQuery(
        { pageNumber, elementsPerPage, searchParams },
        { refetchOnMountOrArgChange: true }
    )

    const onSubmit = async (formData) => {
        setSearchParams(cleanProperties(formData));
        refetch();
    };

    const clearForm = () => {
        navigate(pathname, {});
        setSearchParams({});
        reset();
    };

    useEffect(() => {
        setSearchParams(state || {});
        reset({
            buscador: state?.buscador || "",
            idEstatus: state?.idEstatus || "1",
        });
    }, [reset, setSearchParams, state]);


    return (
        <>
            <main id="main" className="main">
                <Form
                    id="formulario"
                    className="row g-3"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="input-group">
                        <Form.Control
                            type="text"
                            placeholder="Buscar por: Empresa"
                            {...register("buscador")}
                        />
                        <Button type="submit" variant="primary" title={"Buscar"}>
                            <i className="bi bi-search"></i>
                        </Button>
                        <Button
                            className="ms-2"
                            variant="primary"
                            title={"Limpiar"}
                            onClick={(e) => clearForm()}
                        >
                            <i className="bi bi-eraser"></i>
                        </Button>
                        <Button
                            variant="primary"
                            className="ms-2"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#filtros"
                            aria-expanded="false"
                            aria-controls="filtros"
                            title={"Filtros"}
                        >
                            <i className="bi bi-sliders"></i>
                        </Button>
                    </div>

                    <div className="collapse" id="filtros">
                        <Card className="card-body mb-0">
                            <h5 className="mb-3 mt-3 fw-bold">Criterios de filtrado.</h5>
                            <Row>
                                <Col className="border-end col">
                                    <Form.Label htmlFor="fechaSolicitud" className="form-label">
                                        Fecha de solicitud
                                    </Form.Label>
                                    <Col xs={6} className="mb-2">
                                        <Controller
                                            render={({ field }) => (
                                                <DatePicker
                                                    className="form-control"
                                                    id="fechaSolicitud"
                                                    dateFormat="dd/MM/yyyy"
                                                    value={field.value}
                                                    onChange={(date) => {
                                                        field.onChange(dayjs(date).format("DD/MM/YYYY"));
                                                    }}
                                                />
                                            )}
                                            name="fechaSolicitud"
                                            control={control}
                                        />
                                    </Col>
                                </Col>

                                <Col className="border-end col">
                                    <Controller
                                        name="idEstatus"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <Form.Label htmlFor="idEstatus">
                                                    Estatus de la solicitud
                                                </Form.Label>
                                                <Form.Check
                                                    id="estatus.0"
                                                    type="radio"
                                                    label="Todas"
                                                    value=""
                                                    {...register("idEstatus")}
                                                    defaultChecked={!state?.idEstatus}
                                                />
                                                <Form.Check
                                                    id="estatus.123"
                                                    type="radio"
                                                    label="Autorizada"
                                                    value="123" 
                                                    {...register("idEstatus")}
                                                    defaultChecked={parseInt(state?.idEstatus) === 123}
                                                />
                                                <Form.Check
                                                    id="estatus.125"
                                                    type="radio"
                                                    label="Cancelada"
                                                    value="125"
                                                    {...register("idEstatus")}
                                                    defaultChecked={parseInt(state?.idEstatus) === 125}
                                                />
                                                <Form.Check
                                                    id="estatus.124"
                                                    type="radio"
                                                    label="Pendiente"
                                                    value="124"
                                                    {...register("idEstatus")}
                                                    defaultChecked={parseInt(state?.idEstatus) === 124}
                                                />
                                            </>
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Row className="text-end mt-3">
                                <Button type="submit" variant="primary">
                                    Aplicar
                                </Button>
                            </Row>
                        </Card>
                    </div>
                </Form>

                <Row className="mt-3">
                    <Link
                        className="col-12 text-end"
                        to={"/solicitudes/vacaciones/view"}
                    >
                        <Button type="submit" variant="primary">
                            <i className="bi bi-file-earmark-plus-fill"></i> Nueva solicitud
                        </Button>
                    </Link>
                </Row>

                {vacaciones?.data &&
                    vacaciones.data.map((vacacion) => (
                        <VacacionCard
                            key={"vacacion." + vacacion.idVacacion}
                            vacacion={vacacion}
                            refetch={refetch}
                        />
                    ))}

                {vacaciones && (
                    <SeverPagination
                        data={vacaciones.data}
                        currentPage={pageNumber}
                        totalElements={vacaciones.totalElements}
                        elementsPerPage={elementsPerPage}
                        onPageChange={setPageNumber}
                        onElementsPerPageChange={setElementsPerPage}
                    />
                )}

                 {vistaVacaciones && (
                    <VacacionesForm
                        show={vistaVacaciones}
                        onHide={(e) => {
                            setVistaVacaciones(false)
                        }}
                        onSave={refetch}
                    />
                )} 

            </main>
        </>
    )

}

export default Vacaciones