import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const vacacionesApi = createApi({
    reducerPath: 'vacacionesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + 'solicitudes/vacaciones',
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerVacacion: build.query({
            query: (idVacacion) =>  `/${idVacacion}`,
        }),
        obtenerVacaciones: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarVacacion: build.mutation({
            query: (vacacion) => ({
                url: '',
                method: vacacion.idVacacion ? 'PATCH' : 'POST',
                body: vacacion
            }),
        }),
        eliminarVacacion: build.mutation({
            query: (vacacion) =>({
                url: '',
                method: 'DELETE',
                body: vacacion,
            }),
        }),
        buscarVacaciones:build.query({
            query: ({pageNumber, elementsPerPage, searchParams }) => 
                `/buscador?page=${pageNumber}&size=${elementsPerPage}&` + new URLSearchParams(searchParams).toString(),
        }),
    })
})

export const {
    useObtenerVacacionQuery,
    useObtenerVacacionesQuery,
    useLazyObtenerVacacionesQuery,
    useGuardarVacacionMutation,
    useEliminarVacacionMutation,
    useBuscarVacacionesQuery
} = vacacionesApi