import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const diasFestivosApi = createApi({
    reducerPath: 'diasFestivosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "dias/festivos",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerDiaFestivo: build.query({
            query: (idDiaFestivo) => `/${idDiaFestivo}`,
        }),
        obtenerDiasFestivos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarDiaFestivo: build.mutation({
            query: (diaFestivo) => ({
                url: '',
                method: diaFestivo.idDiaFestivo ? 'PUT' : 'POST',
                body: diaFestivo,
            }),
        }),
        eliminarDiaFestivo: build.mutation({
            query: (diaFestivo) => ({
                url: '',
                method: 'DELETE',
                body: diaFestivo,
            }),
        }),
    }),
});
export const {
    useObtenerDiaFestivoQuery,
    useObtenerDiasFestivosQuery,
    useLazyObtenerDiasFestivosQuery,
    useGuardarDiaFestivoMutation,
    useEliminarDiaFestivoMutation
} = diasFestivosApi;
